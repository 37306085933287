<template>
    <div class="mobile-page-layout">
        <div class="mobile-page-header" style="border-bottom: 1px solid #e6e6e6;">
            <div class="mobile-page-header-row">
                <div class="mobile-page-header-right">
                    <router-link :to="{ name: 'HomeIntro'}" class="mobile-page-header-icon pointer back-icon">
                        <i class="header-uil-icon uil uil-angle-right-b"></i>
                    </router-link>
                    <div class="mobile-page-header-text">
                        صرافی‌های جهانی
                    </div>  
                </div>
                <div class="mobile-page-header-actions">  
                    <div class="mobile-page-header-icon pointer" @click="search = !search">
                        <i class="header-uil-icon uil uil-search"></i>
                    </div>
                </div>
            </div>
        </div>

        <slot v-if="loading">
            <div class="tgju-widget-content full-padding">
                <div class="text-center p-2">
                    <div class="widget-loading"></div>
                </div>
            </div>
        </slot>

        <div v-else class="market-main-items-data">


            <div class="page-market-select-row">
                <h3>صرافی ها</h3>
                <div class="page-market-select-input">

                    <v-select :items="items" v-model="selectCoin" class="profile-mobile-view-select">
                        <template v-slot:selection="{ item }">
                            <img class="vselect-image" :src="'https://static.tgju.org/views/default/images/blockchain/' + selectCoin.slug.toUpperCase() + '.svg'"> <span class="vselect-span">{{ item.name }}</span>
                        </template>
                        <template v-slot:item="{ item }">
                            <img class="vselect-image" :src="'https://static.tgju.org/views/default/images/blockchain/' + item.slug.toUpperCase() + '.svg'"> <span class="vselect-span">{{ item.name }}</span>
                        </template>
                    </v-select>

                </div>
            </div>

            <v-text-field v-if="!loading && search" class="mobile-app-input mobile-app-input-modal" label="متن جستجو را وارد نمایید." v-model="searchQuery" style="margin: 10px 10px 7px !important;"></v-text-field>

            <div v-for="(item, index) in filteredList" :key="index" class="market-main-item ">
                <slot v-for="item_sell in items" >
                    <router-link class="market-main-item-row r-low " :to="{ name: 'GlobalExchangeProfile', params: { id: item.id, slug : item.type },}" :key="item_sell.slug" v-if="item_sell.slug == selectCoin.slug && item.items[item_sell.slug]">
                        <div class="market-main-item-col-logo">
                            <img :src="'https://static.tgju.org/views/default/images/exchanges-logo/'+ item.supplier.type +'.png'" :alt="item.category_title">
                        </div>
                        <div class="market-main-item-col item-col-right">
                            <div class="market-main-item-title">
                                <h2>{{ item.category_title }}</h2>
                            </div>
                            <div class="market-main-item-time"><i class="uil uil-clock"></i><span>{{ item.items[item_sell.slug] ? (item.items[item_sell.slug] ? to_yyyy_mm_dd(item.items[item_sell.slug].updated_at) : '0') : '0' }}</span></div>
                        </div>
                        <div class="market-main-item-col item-col-left">
                            <div class="market-main-item-price"><span>{{ item.items[item_sell.slug] ? (item.items[item_sell.slug] ? formatPrice(item.items[item_sell.slug].p) : '0') : '0' }}</span> <div class="item-price-badge" style="padding-left: 0;padding-right: 0px;">دلار</div></div>
                            <div class="market-main-item-change">{{ item.items[item_sell.slug] ? (item.items[item_sell.slug] ? item.items[item_sell.slug].pair : '') : '' }}</div>
                        </div>
                    </router-link>
                </slot>
            </div>
        </div>
    </div>
</template>

<script>
import moment from "moment-jalaali";

export default {
    name: 'GlobalCryptoExchangesView',
    component: {
    },
    data: function () {
        return {
            globalCryptoExchanges: [],
            selectCoin:  { slug: 'btc', name: 'بیت کوین'},
            loading: false,
            type: 'sell',
            searchQuery:'',
            search:false,
            items: [
                { slug: 'btc', name: 'بیت کوین'},
                { slug: 'eth', name: 'اتریوم'},
                { slug: 'sol', name: 'سولانا'},
                { slug: 'doge', name: 'دوج'},
                { slug: 'xrp', name: 'ریپل'},
                { slug: 'trx', name: 'ترون'},
                { slug: 'ltc', name: 'لایت کوین'},
                { slug: 'ada', name: 'کاردانو'},
                { slug: 'xlm', name: 'استلار'},
                { slug: 'bnb', name: 'بایننس کوین'},
                { slug: 'bch', name: 'بیت کوین کش'},
                { slug: 'avax', name: 'آوالانچ'},
            ],
            currencyItems : {
                "btc"  : "بیت کوین",
                "eth"  : "اتریوم",
                "sol" : "سولانا",
                "doge" : "دوج",
                "xrp"  : "ریپل",
                "trx"  : "ترون",
                "ltc"  : "لایت کوین",
                "ada"  : "کاردانو",
                "xlm"  : "استلار",
                "bnb"  : "بایننس کوین",
                "bch"  : "بیت کوین کش",
                "avax" : "آوالانچ",
            },
        }
    },
    mounted() {
        // دریافت اطلاعات تحلیل های تایید شده ی عمومی
        this.loadData();
    },
    computed: {
        filteredList() {
            if(this.searchQuery === '') {
                return this.globalCryptoExchanges;
            }
            return Object.values(this.globalCryptoExchanges).filter(Exchange => {
                return Exchange.category_title.toLowerCase().includes(this.searchQuery.toLowerCase())
            })
        },
    },
    methods: {
        formatPrice(value) {
            if (value === null) {
                return '0';
            }

            value = parseFloat(value);
            return parseFloat(value.toFixed(2)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        },
        to_yyyy_mm_dd(date) {
            return moment(date).format("H:m:s - jYYYY/jMM/jDD");
        },
        loadData() {
            this.loading = true;
            var quotes = '';
            for (var key in this.currencyItems) {
                quotes += key + ',';
            }
            this.$helpers.makeRequest('GET', '/exchanges/global-crypto-exchanges', {
                quotes: quotes,
            }).then(api_response => {
                if ( api_response.status == 200 ) {
                    this.globalCryptoExchanges = api_response.data;
                    this.loading = false;
                }
            });
        }
    }
}
</script>
